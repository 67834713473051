<template>
  <loading v-if="loading" centered />

  <div v-else class="columns is-multiline is-marginless has-padding-50">
    <div class="column is-12">
      <collapse title="Staff controls">
        <task-color :task-id="taskId" />
        <task-controls :task-id="taskId" />
        <site-controls :site-id="task.siteId" />
        <user-controls :user-id="$_.get(site, 'authorId', task.authorId)" />
      </collapse>
    </div>

    <div v-if="task.siteId && site.hostingData" class="column is-12">
      <site-external-logins
        :site="site"
        :tag="'collapse'"
        :title="'External logins'"
      />
    </div>

    <div class="column is-12">
      <task-details :task="task" admin-context />
    </div>

    <div v-if="task.siteId" class="column is-12">
      <collapse title="Site notes">
        <site-notes :site-id="task.siteId" />
      </collapse>
    </div>

    <div v-if="!loadingSite" class="column is-12">
      <collapse title="User notes">
        <user-notes :user-id="$_.get(site, 'authorId', task.authorId)" />
      </collapse>
    </div>

    <div v-if="task.siteId" class="column is-12">
      <collapse title="Site secrets">
        <site-secrets :site-id="task.siteId" />
      </collapse>
    </div>

    <div v-if="task.siteId" class="column is-12">
      <collapse title="Site details">
        <site-details :site="site" />
      </collapse>
    </div>
  </div>
</template>

<script>
import taskProvider from "@shared/tasks/_taskProvider";
export default {
  name: "AdminTaskAside",
  components: {
    "site-external-logins": () => import("@shared/sites/_siteExternalLogins"),
    "site-controls": () => import("@shared/admin/_siteControls"),
    "site-notes": () => import("@shared/sites/_siteNotes"),
    "site-secrets": () => import("@shared/sites/_siteSecrets"),
    "site-details": () => import("@shared/sites/_siteDetails"),
    "task-color": () => import("@shared/tasks/_taskColor"),
    "task-controls": () => import("@shared/admin/_taskControls"),
    "task-details": () => import("@shared/tasks/_taskDetails"),
    "user-controls": () => import("@shared/admin/_userControls"),
    "user-notes": () => import("@shared/user/_userNotes")
  },
  mixins: [taskProvider]
};
</script>
